.dart-button {
  .ant-tooltip-disabled-compatible-wrapper{
    width: 100%;
  }
  .ant-btn{
    font-family: 'GothamBold', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: @dart-border-radius-sm;
    display: flex;
    column-gap: 8px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    .dart-button-content{
      display: flex;
      justify-content: center;
      column-gap: 8px;
    }
    .anticon{
      * {
        fill: white;
      }
    }
  }
  &.sm {
    .ant-btn {
      height: 32px;
      font-size: 12px;
      line-height: 12px;
      padding: 12px 20px;
    }
  }
  &.md {
    .ant-btn {
      height: 36px;
      font-size: 14px;
      line-height: 14px;
      padding: 14px 40px;
    }
  }
  &.lg {
    .ant-btn {
      height: 48px;
      font-size: 16px;
      line-height: 16px;
      padding: 16px 60px;
    }
  }
  &.primary:not(.disabled) {
    .ant-btn {
      background-color: @dart-primary;
      color: white;
    }
  }
  &.dark:not(.disabled) {
    .ant-btn {
      background-color: @dart-dark;
      color: white;
    }
  }
  &.secondary:not(.disabled) {
    .ant-btn {
      --antd-wave-shadow-color: @dart-secondary;
      background-color: @dart-secondary;
      color: white;
    }
  }
  &.danger:not(.disabled) {
    .ant-btn {
      --antd-wave-shadow-color: transparent;
      box-shadow: none;
      background-color: transparent;
      color: @dart-danger;
    }
  }
  &.danger_full:not(.disabled) {
    .ant-btn {
      --antd-wave-shadow-color: transparent;
      box-shadow: none;
      background-color: @dart-danger;
      color: white;
      .anticon{
        * {
          fill: white;
        }
      }
    }
  }
  &.default:not(.disabled) {
    .ant-btn {
      --antd-wave-shadow-color: @dart-gray;
      background-color: transparent;
      color: @dart-gray;
      box-shadow: none;
    }
  }
  &.ghost:not(.disabled) {
    .ant-btn {
      --antd-wave-shadow-color: @dart-primary;
      background-color: transparent;
      color: @dart-primary;
      border: 2px solid @dart-primary;
      box-shadow: none;
      .anticon{
        * {
          fill: @dart-primary;
        }
      }
    }
  }
  &.disabled {
    .ant-btn {
      background-color: @dart-gray;
      color: @dart-dark;
      .anticon{
        * {
          fill: @dart-dark;
        }
      }
    }
    &.default{
      .ant-btn {
        background-color: transparent;
        color: @dart-light;
        .anticon{
          * {
            fill: @dart-light;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";