.review-cc-filters {
  margin-top: 48px;
  margin-bottom: 43px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .review-cc-bulk-actions {
    &.disabled{
      > button:first-child ,
      > button {
        background-color: @dart-gray;
        color: @dart-dark;
        cursor: not-allowed;
        &:hover {
          background-color: @dart-gray;
          color: @dart-dark;
          cursor: not-allowed;
        }
      }
    }
    > button {
      border: 0;
      font-weight: 500;
      font-size: 14px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-family: "GothamBold", sans-serif;
      background-color: @dart-primary;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.85;
        transition: 0.2s;
      }
    }

    > button:first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      border-right: 1px solid white !important;
    }

    > button:last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      border-left: 1px solid white !important;
    }
  }

  .review-cc-action-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
    gap: 16px;

    .filters-wrapper {
      display: flex;
      gap: 20px;
      column-gap: 20px;
      row-gap: 10px;
      flex-wrap: wrap;

      .ant-row.ant-form-item-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .ant-col.ant-form-item-label {
          text-align: left;
          padding-left: 8px;
        }

        .dart-input {
          width: 200px;
        }

        .dart-select {
          .ant-select,
          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            .ant-select-selector {
              border: 0;
              width: 200px !important;
              height: 48px;
              line-height: 48px;
              background: #F6F6F6;
              border-radius: 8px;

              .ant-select-selection-placeholder,
              .ant-select-selection-item {
                line-height: 48px;
              }
            }
          }
        }
      }
    }

    .action-button-wrapper {
      display: flex;
      align-items: flex-end;
    }
  }
}

.review-cc-bulk-actions-dropdown{
  > .ant-btn {
    width: 160px;
    font-weight: 500;
    font-size: 14px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "GothamBold", sans-serif;
    background-color: @dart-primary;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 4px;
    outline: none !important;
    box-shadow: none !important;
    border: 0;
  }
  > .ant-picker{
    outline: none !important;
    box-shadow: none !important;
    border: 0;
    font-weight: 500;
    font-size: 14px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "GothamBold", sans-serif;
    background-color: @dart-primary;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 4px;
    .ant-picker-suffix{
      margin-top: -2px;
    }
    * {
      color: white;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: white;
      opacity: 1;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: white;
      opacity: 1;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: white;
      opacity: 1;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: white;
      opacity: 1;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";