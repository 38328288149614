.warehouse-assign-widget-container {

}

.warehouse-assign-modal-container {
  .ant-modal {
    max-width: 100%;
  }

  .ant-modal-body {
    padding-bottom: 24px !important;
  }

  &.dart-prompt .ant-modal-content .ant-modal-header .head-wrapper {
    margin-top: 0;
    flex-direction: row;
    justify-content: center;
    column-gap: 16px;
    margin-left: -16px;

    .title {
      font-size: 32px;
    }
  }

  .warehouse-assign-modal {
    .selector {
      margin-top: 24px;
    }

    .warehouse-selector {
      margin-top: 32px;
    }

    .two-column {
      display: flex;
      flex-direction: row;
      column-gap: 16px;

      > div {
        flex: 1;
      }
    }

    .selector,
    .warehouse-selector {
      .title {
        height: 16px;
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        text-align: left;
        width: 100%;
        display: block;
        margin-bottom: 12px;
        position: relative;
        .error-message{
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          column-gap: 8px;
          color: @dart-danger;
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .ant-select-dropdown{
      z-index: 2050;
    }

    .selector {
      .dart-compact-select {
        background: #F6F6F6;
        border-radius: 8px;

        .ant-select {
          width: 100%;
        }

        .ant-select-selector {
          width: 100%;
        }

        .ant-select-selection-overflow-item {
          margin-left: 15px;
        }

        .ant-select-selection-placeholder {
          text-align: left;
          position: absolute;
          left: 15px;
          top: 4px;
        }

        .ant-select-arrow {
          margin-left: 0;
          position: absolute;
          right: 20px;
          top: 9px;
        }

        .ant-select-clear {
          position: absolute;
          right: 20px;
          top: 15px;
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";