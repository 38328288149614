.store-selection-table-block {
  .BaseTable__header-cell-text {
    font-family: 'GothamLight', sans-serif;

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;
  }

  .BaseTable__row {
    height: 72px !important;
    font-family: 'GothamRegular', sans-serif;

    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;

    .ant-select-arrow {
      top: 14px;
    }
    .store-selection-table-cell{
      &.has-error{
        svg *{
          fill: @dart-danger;
        }
      }
    }
    .uuid-cell {
      column-gap: 24px;
      font-family: 'GothamBold', sans-serif;

      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      color: @dart-primary;
      &.has-error {
        color: @dart-danger !important;
      }
      &.psps-met {
        color: @dart-secondary;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";