.dart-datepicker {
  .dart-datepicker-label{
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-left: 8px;
    display: block;
    height: 24px;
  }
  .dart-datepicker-target{
    background: #F6F6F6 !important;
    border-radius: 8px;
    height: 48px;
    border: 0;
    width: 100%;
    padding: 0 20px 0 0;
  }
  input,
  input[disabled]{
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: @dart-dark;
    padding: 4px 16px;
  }
  .dart-icon {
    margin-top: -2px;
  }
  .ant-picker-input > input::placeholder {
    color: @dart-dark;
    opacity: 1;
  }
  .ant-picker-focused{
    box-shadow: 0 0 0 2px fade(@dart-primary, 20%);
  }
}

.dart-datepicker-panel{
  .ant-picker-cell-selected{
    .ant-picker-cell-inner{
      background-color: @dart-primary;
      border-color: @dart-primary;
    }
  }
  .ant-picker-cell-today{
    .ant-picker-cell-inner:before{
      border: 1px solid @dart-primary;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";