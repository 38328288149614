.right-sidebar {
  position: fixed;
  bottom: 0;
  top: 0;
  height: 100vh;
  z-index: 1999;
  background: #ffffff;

  &.with-header {
    .ant-layout-sider-children {
      display: flex;
      flex-flow: column;
    }
    .dragger-wrapper {
      overflow: auto;
    }
    .sidebar-header-wrapper {
      padding: 24px;
      border-bottom: 1px solid #E5E9F2;
    }
    .sidebar-footer-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      padding: 12px 24px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-100%);
        width: 100%;
        height: 32px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #2F334B 100%);
        opacity: 0.06;
        pointer-events: none;
      }
    }
  }

  .sider-spin-loader{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 200px;
  }

  &.visible {
    box-shadow: 0px 4px 51px -20px rgba(107, 124, 152, 0.5);
  }

  &:not(.visible) {
    pointer-events: none;
  }

  .dragger-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .dragger {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 15px;
      height: 100%;
      transition: 0.2s;
      z-index: 30;
    }
  }

  .icon-wrapper {
    position: absolute;
    right: 24px;
    top: 12px;
    cursor: pointer;
    background: #F6F6F6;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 202;

    &:hover {
      cursor: pointer;
    }
  }

  .ant-layout-sider-children {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.resizable {
    .dragger-wrapper {
      .dragger {
        &:hover {
          background-color: rgba(@dart-primary, 0.1);
        }

        &.resize-mode {
          background-color: rgba(@dart-primary, 0.2);
          box-shadow: 0px 4px 51px -20px rgba(107, 124, 152, 0.5);
        }
      }
    }
  }
}


.right-sidebar:hover + .sider-backdrop-wrapper.backdrop:before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 998;
  transition: 0.1s;
}

.sider-backdrop-wrapper {
  &.backdrop {
    &:before {
      content: "";
      position: fixed;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      z-index: 998;
      pointer-events: none;
      transition: 0.1s;
    }
  }
}

@media (hover:none), (hover:on-demand) {
  .right-sidebar:hover + .sider-backdrop-wrapper.backdrop:before {
    background-color: transparent;
  }
  .right-sidebar:hover ~ .sider-backdrop-wrapper.backdrop:before {
    background-color: transparent;
  }
}


@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";