.manual-cc-selection-page {
  .selected-style-colors-wrapper {
    margin-top: 74px;
    padding: 24px 0;
    background-color: #F6F6F6;
    margin-right: 32px;
    border-radius: 16px;
    h2{
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 20px;
      line-height: 16px;
      text-transform: uppercase;
      color: @dart-dark;
      padding-bottom: 16px;
      border-bottom: 1px solid @dart-gray-alt;
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 0;
    }
    .selected-style-colors-list{
      max-height: calc(100vh - 582px);
      overflow-y: scroll;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 32px;
    }
    .empty-list-placeholder{
      background: white;
      border-radius: 8px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.replenishment-name-wrapper{
  margin-top: 74px;
  .dart-button{
    margin-top: 26px;
    button {
      width: 100%;
    }
  }
}

// used for top header
.main-layout-content {
  .manual-cc-selection-page {
    padding: 64px 32px;

    .container-content {
      max-width: 1028px;
      margin: 0 auto;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";