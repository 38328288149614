.alloc-q-dashboard-page {

}

// used for top header
.main-layout-content {
  .alloc-q-dashboard-page{
    padding-top: 128px;
    .container-content{
      padding: 0 64px;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";