.dart-dynamic-tabs-with-scroll {
  background: transparent;
  position: relative;

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav-wrap {
    &:after {
      display: none !important;
    }

    &:before {
      display: none !important;
    }
  }

  .tab-left-shadow {
    pointer-events: none;
    width: 50px;
    height: 52px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
  }

  .tab-right-shadow {
    pointer-events: none;
    width: 50px;
    height: 52px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &.with-navigation {
    .tab-right-shadow {
      right: 114px !important;
    }
  }

  .tabs-navigation {
    display: flex;
    column-gap: 6px;
    margin-left: 12px;

    .dart-button {
      button {
        width: 48px;
        height: 48px;
        background: @dart-primary;
        border-radius: 12px;
        padding: 0;
        transition: 0.2s;

        .dart-button-content {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            font-size: 16px;
          }
        }
      }

      &.disabled {
        button {
          background: @dart-gray;
          transition: 0.2s;

          .dart-button-content {
            svg * {
              fill: white
            }
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";