.alloc-q-edit-page {
  .dart-page-title {
    height: 144px;
    margin-top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 72px;

    .left {
      .back-button {
        margin-left: 0;
      }
    }

    .right {
      position: initial;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      .totals{
        position: absolute;
        top: 72px;
        display: flex;
        column-gap: 32px;
        > div {
          &:first-child{
            margin-left: 64px;
          }
          display: flex;
          flex-direction: column;
          width: 147px;
          .value{
            font-family: 'GothamLight', sans-serif;
            font-weight: 325;
            font-size: 32px;
            line-height: 38px;
            color: #333E47;
          }
          .label{
            font-family: 'GothamLight', sans-serif;
            font-weight: 325;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
          }
        }
      }
    }

    .center {
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: @dart-dark;
    }
  }

  .alloc-q-edit-table {
    /*&.condense {
      .BaseTable__body {
        //height: calc(100vh - 632px) !important;
      }

      .BaseTable__table-frozen-left {
        //min-height: calc(100vh - 613px) !important;
      }
      .BaseTable__header{
        //height: 35px !important;
      }
    }

    .BaseTable__body {
      //height: calc(100vh - 356px) !important;
    }

    .BaseTable__table-frozen-left {
      //min-height: calc(100vh - 339px) !important;
    }

    .BaseTable__header{
      //height: 35px !important;
    }*/

    .alloc-q-editable-cell {
      width: 100%;
      height: 100%;
      .linked-uas-values{
        position: absolute;
        top: 1px;
        left: 1px;
      }
      &.selected {
        background-color: #e8ecf4;
      }
      &.updated{
        background-color: #E8F4EF;
      }
      &.flagged{
        background-color: #FFEAEA !important;
      }
      .dart-editable-cell{
        width: 100%;
        height: 100%;
      }
      .view-mode-wrapper{
        width: 100%;
        height: 100%;
      }
      .edit-mode-wrapper{
        height: 100%;
      }
    }

    .alloc-q-cell {
      background-color: #F6F6F6;
      border: 1px solid @dart-gray-alt;
      border-top: none;
      padding: 0 16px;
      position: relative;
      font-family: 'GothamLight', sans-serif;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      font-weight: 325;
      font-size: 12px;
      line-height: 49px;
      color: #000000;
      &.datepicker-cell {
        .dart-datepicker .ant-picker-focused{
          box-shadow: none;
        }
        .dart-datepicker .dart-datepicker-target {
          background: inherit !important;
        }
        input {
          text-align: center;
          font-family: 'GothamLight', sans-serif;
          white-space: nowrap;
          font-weight: 325;
          font-size: 12px;
          line-height: 49px;
          color: #000000;
          padding: 4px 6px;
        }
      }
      .ant-select-arrow{
        margin-top: -9px;
      }
      .cc-with-comment{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .dart-icon{
          margin-right: -8px;
          cursor: pointer;
        }
      }
      .underline {
        border-bottom: 1px solid @dart-dark;
        cursor: pointer;
        line-height: 16px;
        &:hover{
          border-bottom: 1px solid @dart-primary;
        }
      }

      &.with-interaction {
        padding: 0;
      }

      &.white-background{
        background-color: #FFFFFF;
      }

      .dart-editable-cell {
        cursor: pointer;
      }

      &.disable-editing {
        .dart-editable-cell {
          cursor: initial;
        }
      }

      .dart-editable-cell:hover .view-mode-wrapper {
        text-decoration: underline;
      }

      .inventory-data-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        column-gap: 6px;
        height: 100%;
        &.with-tooltip {
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 44px;
            column-gap: 8px;
            width: 100%;
            > span:first-child {
              font-weight: bold;
            }
          }
        }

        .value-item{
          flex: 1;
          display: flex;
          flex-direction: column;
          height: 36px;
          > span {
            line-height: 18px;
            &:first-child {
              font-weight: bold;
            }
          }
          &:not(:last-child){
            border-right: 1px solid #00000033;
          }
        }
      }
    }

    .alloc-q-fixed-cell {
      border: 1px solid @dart-gray-alt;
      border-top: none;
    }

    .alloc-q-overview-table-cell:not(.frozen) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .alloc-q-overview-table-cell.updated {
      background-color: #E8F4EF;
      height: 100%;
      width: 100%;
    }

    .alloc-q-overview-table-cell.flagged {
      background-color: #FFEAEA !important;
      height: 100%;
      width: 100%;
    }

    .alloc-q-overview-table-cell.frozen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .select-toggle-button {
      position: absolute;
      top: 0;
      background-color: @dart-dark;
      color: white;
      width: 90px;
      text-align: center;
      display: block;
      padding: 0;
      font-family: 'GothamLight', sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 34px;
      border-right: 1px solid black;
      height: 34px;
    }
  }
}

// used for top header
.main-layout-content {
  .alloc-q-edit-page {
    padding-top: 144px;

    .container-content {
      padding: 0 64px;
    }

    .dart-page-title {
      background-color: @dart-light;
      position: fixed;
      width: 100vw;
      top: 94px;

      .left {
        .back-button {
          margin-left: 64px;
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";