.replenishment-dashboard-page {
  .wrapper {
    width: 100%;
    background-color: #F6F6F6;
    border-radius: 16px;

    &.ready-to-approve-wrapper {
      min-height: 256px;
      display: flex;
      justify-content: center;
    }

    &.replenishment-summary-wrapper {
      min-height: 512px;
    }

    &.replenishment-history-wrapper {
      min-height: 360px;
    }

    &.ready-to-approve-wrapper,
    &.replenishment-summary-wrapper {
      padding: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      h1{
        font-size: 64px;
        font-family: "GothamRegular", sans-serif;
        font-weight: 600;
        color: #cecece;
      }
    }
  }
}

// used for top header
.main-layout-content {
  .replenishment-dashboard-page {
    padding-top: 32px;

    .container-content {
      max-width: 1028px;
      margin: 0 auto;
      padding: 0 64px 32px;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";