.store-list-item{
  background-color: @dart-light;
  padding: 16px;
  border-radius: 16px;
  .card-head{
    display: flex;
    justify-content: space-between;
    height: 18px;
    .left{
      font-family: 'GothamBlack', sans-serif;

      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      color: @dart-dark;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      .vertical-splitter{
        margin: 0 8px;
        height: 16px;
        width: 1px;
        background-color: @dart-gray;
        display: inline-block;
      }
    }
  }
  .card-body{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background-color: white;
    border-radius: 8px;
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    .collapse-content {
      display: flex;
      justify-content: space-between;
      height: 18px;
      padding: 16px 18px;
      box-sizing: content-box;
      cursor: pointer;
      .left {
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }

      .right {
        display: flex;
        align-items: center;
        height: 18px;
        .ant-btn.ant-btn-icon-only {
          margin-top: 2px;
          .dart-icon {
            margin: 0 auto;
          }
        }
        .store-status-wrapper{
          display: flex;
          column-gap: 32px;
          margin-right: 60px;
          .store-status-extra{
            min-width: 184px;
          }
          .store-status {
            color: @dart-dark;
            padding-left: 20px;
            position: relative;
            width: 70px;
            text-align: left;
            text-transform: capitalize;
            &.LIVE:before ,
            &.PAUSED:before ,
            &.CLOSED:before ,
            &.PLANNING:before {
              content: "";
              width: 12px;
              height: 12px;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 2px;
            }
            &.LIVE:before {
              background-color: #19945D;
            }
            &.PAUSED:before {
              background-color: #FFDD00;
            }
            &.CLOSED:before {
              background-color: #EF1919;
            }
            &.PLANNING:before {
              background-color: #5FC7CB;
            }
          }
        }
      }
    }
    .expand-content{
      width: 100%;
      height: 0;
      overflow: hidden;
      white-space: nowrap;
      transition: 0.3s;
      padding: 0 18px;
      opacity: 0;
      &.has-tags{
        &.visible{
          height: 340px;
        }
      }
      &.visible{
        height: 295px;
        transition: 0.3s;
        padding: 0 18px 16px 18px;
        margin-top: -16px;
        opacity: 1;
      }
      .store-tags-wrapper{
        display: flex;
        padding-top: 32px;
        padding-bottom: 16px;
        column-gap: 8px;
        flex-wrap: nowrap;
        max-width: 100%;
        overflow: hidden;
        .tag{
          background: transparent;
          border: 1px solid @dart-gray-alt;
          border-radius: 24px;
          padding: 7px 12px;
        }
      }
      .store-type{
        font-size: 12px;
        line-height: 18px;
        color: @dart-dark;
        padding-top: 8px;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid @dart-gray-alt;
      }
      .store-departments{
        display: flex;
        flex-wrap: wrap;
        column-gap: 32px;
        row-gap: 32px;
        padding-top: 16px;
        .department{
          width: 182px;
          flex: 1;
          .department-name{
            text-transform: uppercase;
            font-family: 'GothamBold', sans-serif;
            font-weight: 450;
            font-size: 12px;
            line-height: 12px;
          }
          .department-divisions{
            .division{
              margin-top: 16px;
              padding-bottom: 8px;
              border-bottom: 1px solid @dart-light;
              color: black;
              font-size: 12px;
              line-height: 18px;
              .division-tier{
                float: right;
                color: @dart-dark;
              }
            }
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";