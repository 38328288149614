.newness-filters {
  margin-bottom: 34px;
  position: relative;
  .history-button.ant-btn{
    position: absolute;
    left: 0;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    display: flex;
    column-gap: 6px;
    align-items: center;
    font-family: "GothamLight", sans-serif;
    font-weight: 600;
    color: @dart-dark;
    padding: 0;
    height: 24px;
    margin-top: 4px;
    .dart-icon{
      *{ fill: @dart-dark }
    }
  }
  .ant-form-item{
    margin-bottom: 0;
  }
  .order-type-selection{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.2s;
    &.with-vertical-offset{
      top: -32px;
      transition: 0.2s;
    }
    .dart-datepicker-target{
      height: 30px;
      width: 190px;
    }
  }
  .allocation-action-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
    gap: 16px;
    .action-button-wrapper{
      display: flex;
      align-items: flex-end;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";