.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #E87324;
  color: #fff;
}

.dart-multiple-datepicker-dropdown .ant-picker-dropdown {
  top: 0 !important;
}

.dart-multiple-datepicker-dropdown .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.85) !important;
  background: transparent !important;
  transition: background 0.3s, border 0.3s;
  width: 70px;
  height: 44px;
  line-height: 44px;

  &.selected {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 70px;
    height: 44px;
    line-height: 44px;
    background-color: #E87324 !important;
    color: #fff !important;
    margin: auto;
    border-radius: 2px;
    transition: background 0.3s, border 0.3s;
  }
}

.dart-multiple-datepicker-dropdown .ant-picker-cell-disabled::before {
  background-color: #E87324!important;
}

.dart-multiple-datepicker-dropdown .ant-picker-cell-disabled .ant-picker-cell-inner {
  color: #fff!important;
}

.dart-multiple-datepicker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  content: none;
}
.dart-multiple-datepicker .ant-select-selector {
  min-width: 180px;
  background-color: #F6F6F6 !important;
  border-radius: 8px !important;
  border: none !important;
  * {
    outline: none !important;
  }
}

.dart-multiple-datepicker{
  .selected-date-tag{
    border-radius: 8px;
    background-color: #E87324;
    color: #FFFFFF;
    .anticon *{
      fill: white
    }
  }
  .ant-select-selection-overflow-item{
    .ant-select-selection-item{
      border-radius: 8px;
      background-color: #E87324;
    }
    .ant-select-selection-item-content{
      color: #FFFFFF;
    }
  }
  .ant-select-selector{
    background-color: #f2f2f2 !important;
    .ant-select-selection-placeholder{
      color: #000000;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";