.allocation-readiness-wrapper {
  .ant-tabs-nav{
    margin-bottom: 34px;
    &:before{
      display: none;
    }
    .ant-tabs-ink-bar{
      display: none;
    }
    .ant-tabs-tab{
      font-family: 'GothamRegular', sans-serif;

      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      .ant-tabs-tab-btn {
        color: @dart-mid-gray;
      }
      &.ant-tabs-tab-active{
        .ant-tabs-tab-btn {
          color: @dart-primary;
        }
      }
    }
  }
  &.compact{
    .ant-tabs-nav {
      margin-bottom: 18px;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";