.replenish-sku-details {
  background: #FAFAFA;

  .table-wrapper {
    padding: 0 16px 0 16px;

    .BaseTable{
      background: #FAFAFA;
    }
  }

  .replenish-sku-details-cell-parent {
    border-left: 1px solid #E1E2E3;
    border-right: 1px solid #E1E2E3;
    background: @dart-light;

    &.action-cell{
      .replenish-cc-details-cell{
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        height: 48px;
        cursor: pointer;
      }
    }

    .dart-editable-cell{
      cursor: pointer;
    }

    &.white-background {
      background: white;
    }
    &.store-details{
      .replenish-sku-details-cell{
        width: 100%;
      }
    }
  }

  .replenish-sku-details-cell-parent.with-sub-cells {
    padding: 0 !important;
  }

  .replenish-sku-details-cell {
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;

    .left {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 8px;

      .store-name {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span {
        font-family: 'GothamLight', sans-serif;

        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-dark;

        &.vertical-splitter {
          height: 16px;
          width: 1px;
          background-color: @dart-gray;
          display: block;
        }

        &.store-status {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }

    &.with-sub-cells {
      width: 100%;
    }

    .sub-cells {
      display: flex;
      column-gap: 0;

      .sub-cell {
        min-width: 53px;
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid @dart-gray;
        flex: 1;

        &:last-child {
          border-right: 1px solid transparent;
        }

        > span:first-child {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: @dart-mid-gray;
        }

        > span:last-child {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: @dart-dark;
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";