.grouped-poa-setup {
  min-height: 64px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  &.has-expanded-content{
    border-radius: 8px 8px 0 0;
    transition: 0.3s;
  }
  .card-head{
    display: flex;
    justify-content: space-between;
    height: 64px;
    padding: 16px;
    .left{
      display: flex;
      align-items: center;
      column-gap: 8px;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      .checkbox-department{
        margin-top: -3px;
      }
      .title {
        font-family: 'GothamBold', sans-serif;

        font-weight: 450;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        text-transform: uppercase;
      }
    }
    .right{
      display: flex;
      align-items: center;
      cursor: pointer;
      column-gap: 24px;
      height: 64px;
      margin-top: -16px;
      user-select: none;
      min-width: 120px;
      justify-content: flex-end;
      .content{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-mid-gray;
      }
      .collapse-arrow *{
        fill: #000000;
      }
    }
  }
  .expand-content{
    width: 100%;
    height: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.3s;
    opacity: 0;
    position: relative;
    &.visible{
      height: 568px;
      transition: 0.3s;
      margin-top: -16px;
      opacity: 1;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";