.newness-pill-filters-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 28px;
  row-gap: 12px;
  gap: 28px;

  > div > span {
    font-family: 'GothamLight', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: @dart-mid-gray;
    margin-bottom: 12px;
    display: block;
    user-select: none;
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";