.store-assortment-tiers{
  margin-top: 40px;
  position: relative;
  &.disabled{
    &:before{
      content: "";
      height: calc(100% - 40px);
      width: 100%;
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 93;
      backdrop-filter: blur(2px);
      background-color: transparent;
      cursor: not-allowed;
    }
  }
  .ant-form-item-row{
    flex-direction: column;
  }
  .ant-form-item{
    margin-bottom: 32px;
  }
  .two-line{
    display: flex;
    flex-direction: column;
    &:not(:last-child){
      border-bottom: 1px dashed @dart-gray;
      margin-bottom: 24px;
    }
    + .light-splitter{
      margin-top: -25px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:last-child {
      width: 100%;
    }
    .ant-form-item:first-child {
      margin-bottom: 16px;
    }
    .ant-form-item-label{
      margin-bottom: 6px;
    }
  }
  .ant-form-item-label{
    display: block;
    text-align: left;
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
    label{
      height: 16px;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
  h2, h3{
    font-family: 'GothamRegular', sans-serif;

    font-weight: 450;
    text-transform: uppercase;
    color: @dart-dark;
    margin-bottom: 34px;
    margin-top: 0;
  }
  h2{
    font-size: 20px;
    line-height: 16px;
    height: 16px;
    span{
      font-size: 11px;
      text-align: right;
      float: right;
      color: @dart-secondary;
    }
  }
  h3{
    font-size: 16px;
    line-height: 20px;
    height: 20px;
  }
  hr.light-splitter{
    border: none;
    border-bottom: 1px solid @dart-gray-alt;
    margin: 8px 0 34px;
    &.opacity{
      opacity: 0.35;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";