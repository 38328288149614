.newness-dropdown-filters-block {
  margin-top: 28px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.newness-dropdown-filters-block-bottom-row{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .dart-multiple-datepicker{
    .ant-select-selector{
      max-width: 280px;
      min-width: 280px;
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";