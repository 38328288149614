.alloc-q-overview-widget {
  margin-top: 0;

  .alloc-q-overview-single {
    border-radius: 16px;
    background-color: @dart-light;
    padding: 16px;
    margin-bottom: 16px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      align-items: center;
      cursor: pointer;

      .title {
        font-family: 'GothamRegular', sans-serif;
        font-weight: 450;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: @dart-dark;
      }

      .extra {
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-dark;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 8px;
      }
    }

    .totals {
      width: 100%;
      border-top: 1px solid #E1E2E3;
      display: flex;
      column-gap: 32px;
      padding-top: 8px;
      margin-bottom: 16px;

      > div {
        display: flex;
        flex-direction: column;
        width: 147px;
        height: 56px;

        .value {
          font-family: 'GothamLight', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 38px;
          color: #333E47;
        }

        .label {
          font-family: 'GothamLight', sans-serif;
          font-style: normal;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .expand-content.visible {
        margin-top: 0;
      }

      .expand-content {
        .BaseTable__body {
          height: 150px !important;
        }

        .BaseTable__table-frozen-left {
          min-height: 184px !important;
        }

        .alloc-q-cell {
          background-color: #F6F6F6;
          border: 1px solid @dart-gray-alt;
          border-top: none;
          padding: 0 16px;
          position: relative;
          font-family: 'GothamLight', sans-serif;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          font-weight: 325;
          font-size: 12px;
          line-height: 49px;
          color: #000000;
          &.datepicker-cell {
            .dart-datepicker .ant-picker-focused{
              box-shadow: none;
            }
            .dart-datepicker .dart-datepicker-target {
              background: inherit !important;
            }
            input {
              text-align: center;
              font-family: 'GothamLight', sans-serif;
              white-space: nowrap;
              font-weight: 325;
              font-size: 12px;
              line-height: 49px;
              color: #000000;
              padding: 4px 6px;
            }
          }
          .ant-select-arrow{
            margin-top: -9px;
          }
          .cc-with-comment{
            position: relative;
            height: 100%;
            .dart-icon{
              right: -8px;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              position: absolute;
            }
          }
          .underline {
            border-bottom: 1px solid @dart-dark;
            cursor: pointer;
            line-height: 16px;
            &:hover{
              border-bottom: 1px solid @dart-primary;
            }
          }

          &.with-interaction {
            padding: 0;
          }

          &.white-background {
            background-color: #FFFFFF;
          }

          .dart-editable-cell {
            cursor: pointer;
          }

          &.disable-editing {
            .dart-editable-cell {
              cursor: initial;
            }
          }

          .dart-editable-cell:hover .view-mode-wrapper {
            text-decoration: underline;
          }

          .inventory-data-wrapper {
            display: flex;
            justify-content: space-around;
            align-items: center;
            column-gap: 6px;
            height: 100%;
            &.with-tooltip {
              > span {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 44px;
                column-gap: 8px;
                width: 100%;
                > span:first-child {
                  font-weight: bold;
                }
              }
            }
            .value-item{
              flex: 1;
              display: flex;
              flex-direction: column;
              height: 36px;
              > span {
                line-height: 18px;
                &:first-child {
                  font-weight: bold;
                }
              }
              &:not(:last-child){
                border-right: 1px solid #00000033;
              }
            }
          }
        }

        .alloc-q-editable-cell {
          border-top: none;
          padding: 0;
          position: relative;
          width: 100%;
          height: 100%;

          &.updated {
            background-color: #E8F4EF;
          }

          &.flagged {
            background-color: #FFEAEA !important;
          }

          .dart-editable-cell {
            width: 100%;
            height: 100%;
          }

          .view-mode-wrapper {
            width: 100%;
            height: 100%;
          }

          .edit-mode-wrapper {
            height: 100%;
          }

          .dart-editable-cell {
            cursor: pointer;
          }

          &.disable-editing {
            .dart-editable-cell {
              cursor: initial;
            }
          }

          .dart-editable-cell:hover .view-mode-wrapper {
            text-decoration: underline;
          }
        }

        .alloc-q-fixed-cell {
          border: 1px solid @dart-gray-alt;
          border-top: none;
        }

        .alloc-q-overview-table-cell:not(.frozen) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .alloc-q-overview-table-cell.updated {
          background-color: #E8F4EF;
          height: 100%;
          width: 100%;
        }

        .alloc-q-overview-table-cell.flagged {
          background-color: #FFEAEA !important;
          height: 100%;
          width: 100%;
        }

        .alloc-q-overview-table-cell.frozen {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 8px;

            span {
              font-family: 'GothamLight', sans-serif;

              font-weight: 325;
              font-size: 12px;
              line-height: 18px;
              color: @dart-dark;

              &.vertical-splitter {
                height: 16px;
                width: 1px;
                background-color: @dart-gray;
                display: block;
              }

              &.store-status {
                width: 8px;
                height: 8px;
                border-radius: 50%;
              }
            }
          }

          .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            .contribution {
              font-size: 12px;
              line-height: 18px;
              color: @dart-dark;

              &.flagged {
                color: red;
              }
            }

            .table-row-menu {
              right: 32px !important;
            }
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";