.dart-progress {
  .title{
    margin-bottom: 22px;
    font-family: 'GothamBold', sans-serif;
    font-weight: 450;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: @dart-dark;
  }
  .chunks-wrapper{
    background-color: #E1E2E3;
    display: flex;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    .chunk:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .footer-wrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";