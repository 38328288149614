.replenishment-history-section {
  padding: 32px 32px 16px 32px;
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    column-gap: 8px;
    margin-bottom: 16px;
    font-family: "GothamLight", sans-serif;
    font-weight: 350;
    font-size: 12px;
    .vertical-splitter {
      width: 1px;
      height: 12px;
      background: @dart-gray-alt;
      align-self: center;
    }
  }

  .heading h1 {
    color: @dart-dark;
    font-size: 20px;
    font-family: "GothamRegular", sans-serif;
    font-weight: 450;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  > hr {
    width: 100%;
    margin-top: 0;
    margin-bottom: 32px;
    border: 0;
    height: 1px;
    background: #E1E2E3;
  }

  .replenishment-history-table {
    .BaseTable,
    .BaseTable__table,
    .BaseTable__empty-layer {
      background: rgb(246, 246, 246);
    }

    &.dart-table .ant-empty {
      margin-top: 16px;
    }

    .replenishment-history-table-cell-wrapper {
      padding: 0 !important;
      background: white;

      &:first-child {
        .replenishment-history-table-cell {
          border-left: 1px solid @dart-gray-alt;
        }
      }
    }

    .replenishment-history-table-cell {
      width: 100%;
      height: 47px;
      color: @dart-dark;
      font-size: 12px;
      font-family: "GothamLight", sans-serif;
      font-weight: 325;
      line-height: 18px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid @dart-gray-alt;

      &.draft {
        background: @dart-light;
      }

      &.invalid {
        background: @dart-danger;
      }

      &.vertical-data {
        flex-direction: column;
        justify-content: center;
      }

      &.underlined {
        text-decoration: underline;
        cursor: pointer;
      }

      &.text-left {
        text-align: left;
        width: 100%;
      }

      .ellipsis {
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
        max-width: 150px;
        vertical-align: baseline;
        width: 100%;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2155665895/src/src/configs/variables.less";